<template>
  <div>
    <b-card title="Détails FAQ">
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
        >
          <b-form-group label="Catégorie">
            <b-form-select
              v-model="$v.details.id.$model"
              :options="options"
              :class="{ 'is-invalid': $v.details.id.$error }"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
        >
          <b-form-group label="Question">
            <b-form-input
              v-model="$v.details.question.$model"
              type="text"
              :class="{ 'is-invalid': $v.details.question.$error }"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group label="Réponse">
            <b-form-textarea
              v-model="$v.details.response.$model"
              :class="{ 'is-invalid': $v.details.response.$error }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col sm="12">
        <div
          v-if="process"
          class="d-flex justify-content-center mt-1"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
        <div class="mt-3">
          <b-button
            variant="secondary"
            class="float-left"
            :disabled="process"
            @click="resetForm"
          >
            {{ $t("form.refresh") }}
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            class="ml-2 float-right"
            :disabled="process"
            @click="tryToSave"
          >
            Ajouter
          </b-button>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      allSections: [],
      details: {
        id: null,
        question: null,
        response: null,
      },
    }
  },
  validations: {
    details: {
      id: {
        required,
      },
      question: {
        required,
      },
      response: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'faqDetailsSuccess',
      error: 'faqDetailsError',
      process: 'faqDetailsProcess',
    }),
    options() {
      const options = this.allSections
      const data = [
        {
          value: null,
          text: 'Choisir la catégorie',
        },
      ]
      if (options.length > 0) {
        options.forEach(item => {
          const current = {
            value: item.id,
            text: item.section,
          }
          data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllSections()
      },
    },
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Détails FAQ',
            text: 'Ajouté avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Détails FAQ',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['newFaqDetails']),
    formatter(value) {
      return value.toUpperCase()
    },
    async getAllSections() {
      this.allSections = await this.$http
        .get('/faq/all',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res.data
          return data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Détails FAQ',
              text: 'Une erreur est survenue lors de la récupération',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    resetForm() {
      this.details = {
        id: null,
        question: null,
        response: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.newFaqDetails({
        faq_section_id: this.details.id,
        question: this.details.question,
        response: this.details.response,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
</style>
